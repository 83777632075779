import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../app/usermanagement.service";
import {ExcelService} from 'src/app/inbound-report/excel.service';
import { LoginService } from 'src/app/login.service';
import { DatePipe } from '@angular/common';
import { ReportService } from '../../app/inbound-report/report.service';
import * as jsPDF from "jspdf";
import 'jspdf-autotable';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NotificationService } from 'src/app/notification/notification.service';
import * as moment from 'moment';
import { SpinnerCircularModule } from 'spinners-angular/spinner-circular';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-caller-report',
  templateUrl: './caller-report.component.html',
  styleUrls: ['./caller-report.component.css'],
  providers: [DatePipe]
})



export class CallerReportComponent implements OnInit {
  datas: any;
  voiceRecordData: any = new Array();
  userData: any;
  searchData: string = '';
  callstatusagent:string = '';
  current_page: any;
  DialerReportData: any;
  goPageNumber: string;
  last_page: any;
  callerIdData: any;
  user_type: any;
  pdfData: any;


  fromdate: string = "";
  todate: string = "";

  today = new Date();
  myDate = new Date();
  // todate: string;
  today_informat = this.datePipe.transform(this.today, 'yyyy-MM-dd');


  tomorrow = new Date(this.today.setDate(this.today.getDate() + 1));
  tdy = new Date(this.myDate.setDate(this.myDate.getDate()));

  ExcelPdfReportData: any = new Array();

  wordarray: any = new Array();



   // Settingsforcomment
   commentopen:boolean=false;
   comment:any
   call_id:any;

   defaultDateFrom: Date;
   defaultDateTo: Date;

  constructor(
    private usermanagementservice: UsermanagementService,
    private loginservice: LoginService,
    private datePipe: DatePipe,
    private reportservice: ReportService,
    private notifyService: NotificationService,
    private excelService: ExcelService,

  ) {

  }


  ngOnInit() {
    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);

    this.loadTodayData(1)
  }
  
  loadTodayData(pageNumberData) {


    if (this.fromdate == undefined || this.fromdate == "") {
      var from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd 00:00:00');
      var to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd 23:59:59');
    } else {
      var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
      var to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");  
    }




    this.userData = this.loginservice.getUserdata(true);
    console.log("user Data --------> ",this.userData,"    ",this.userData.usertype)
    this.user_type = this.userData.usertype

    console.log("====searchData===",this.searchData, from, to)
    console.log("====callstatusagent===",this.callstatusagent)

    var body = { "userId": this.userData.id, "from": from, "to": to, "search": this.searchData, "callstatus": this.callstatusagent, 'callerId': this.callerIdData, 'user_type':this.user_type }
    this.usermanagementservice.load_date_caller_report(body, pageNumberData).subscribe(
      (data: any) => {
        this.datas = data.results
        this.current_page = data.current_page
        this.last_page = data.total_pages;
        // alert(this.datas.date)
      }
    )
  }

  onFromDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateFrom = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    }
  }
  onToDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateTo = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);
    }
  }
  
  SearchReport() {
    this.loadTodayData(1)
  }

  showData(callerId) {
    this.callerIdData = "91" + callerId
    this.loadTodayData(this.current_page)
  }
  


  fetchNextorPrev() {
    this.loadTodayData(this.current_page - 1)
  }
  
  fetchNextorNext() {
    this.loadTodayData(this.current_page + 1)
  }

  fetchFirst() {
    this.loadTodayData(1)
  }
  fetchLast() {
    this.loadTodayData(this.last_page)
  }
  fetchNumber(page) {
    if (parseInt(page)=== parseInt(page, 10) && page > 0 && page <= this.last_page) {
      this.current_page = parseInt(page);
      this.loadTodayData(page);
    }
    else{
      Swal.fire('Oops..!','Enter Valid Number', 'warning');
    }
    // this.loadTodayData(page)
  }
  searchBox() {
    this.loadTodayData(1)
  }

  getcallrecord(date, vffile) {
    // debugger;
    this.user_type = this.loginservice.getUserdata(true).usertype
    var from;
    var to;
    this.reportservice.getcallrecord(date,vffile).subscribe(
      (res: any) => {
        this.DialerReportData = res["data"]

        if (this.fromdate == undefined) {
          from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd 00:00:00');
          to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd 23:59:59');
        } else {
          from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
          to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");
        }

        var body = { "userId": this.userData.id, "from": from, "to": to, "search": this.searchData, "callstatus": this.callstatusagent, 'callerId': this.callerIdData, 'user_type':this.user_type }

        this.usermanagementservice.load_date_caller_report(body,this.current_page).subscribe(
          (data: any) => {
            this.datas = data.results
            this.current_page = data.current_page
            this.last_page = data.total_pages;
            this.notifyService.sendNotification('Downloaded', 'Download Voice successfully', 'is-success')
          }
        )

      }
    )
  }

  CloseComment(){
    console.log("=============TRYING TO CLOSE 2====")
    this.commentopen = false
    this.call_id=""
    this.comment =""
  }


  exportAsXLSX(): void {
    var text;
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");
    this.user_type = this.loginservice.getUserdata(true).usertype
    var body = { "userId": this.userData.id, "from": from, "to": to, "search": this.searchData, "callstatus": this.callstatusagent, 'callerId': this.callerIdData,"download": "yes", 'user_type':this.user_type }

    this.usermanagementservice.load_date_caller_report(body, this.current_page)
      .subscribe((res: any) => {
        this.ExcelPdfReportData = res["data"];
        for (let element of this.ExcelPdfReportData) {
          if (element["destinationType"] == "6") {
            var excel_destination_name = "AUDIO";
          } else {
            excel_destination_name = element["queueName"];
          }

          var objz = {
            DID: element["didnumber"],
            Customer: element["callerId"],
            "countrycode": element["country_code"],
            "Customer Name": element["customer"]["name"] || "",
            Date: element["legAstartTime"],
            "Destination Name": element["quenames"], 
            Duration: element["durationTime"],
            "Connected Duration": element["connectedDurations"],
            "Agent Number": element["agentNumber"],
            "Agent Name": element["agentName"],
            "Comment": element["comment"],
            // DTMF: element["DTMS"],
            Status: element["callStatus"],
          };

          this.wordarray.push(objz);
        }
        if ((this.fromdate, this.todate)) {
          text = "Caller Report from: " + from + " To: " + to + "";
        } else {
          text = "Caller Report of " + this.today_informat;
        }
        this.excelService.exportAsExcelFile(this.wordarray, text);
        this.wordarray = [];
      });
  }

  downloads() {
    var countSlNo = 1;
    var newarray = [];
    var head = [
      [
        "SlNo",
        "DID",
        "Source",
        "Customer Name",
        "Country code",
        "Date",
        "Department Name",
        "Call Duration",
        "Connected Duration",
        "Agent",
        "Agent Name",
        "Comment",
        "Status",
        // "DTMF",
      ],
    ];
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");
    this.user_type = this.loginservice.getUserdata(true).usertype

    var body = { "userId": this.userData.id, "from": from, "to": to, "search": this.searchData, "callstatus": this.callstatusagent, 'callerId': this.callerIdData, "download": "yes", 'user_type':this.user_type }
    
    this.usermanagementservice.load_date_caller_report(body, this.current_page
      )
      .subscribe((res: any) => {
        this.ExcelPdfReportData = res["data"];
        for (let element of this.ExcelPdfReportData) {
          newarray.push([
            countSlNo,
            element["didnumber"],
            element["callerId"],            
            element["customer"]["name"] || "",
            element["country_code"],
            element["legAstartTime"],
            element["quenames"],
            element["durationTime"],
            element["connectedDurations"],
            element["agentNumber"],
            element["agentName"],
            element["comment"],
            
            element["callStatus"],
            // element["DTMS"],
            // element["comment"],
          ]);
          countSlNo = countSlNo + 1;
        }
        var pdf = new jsPDF();
        pdf.text("Caller Report", 11, 8);
        pdf.setFontSize(3);
        pdf.setTextColor(100);
        (pdf as any).autoTable({
          head: head,
          body: newarray,
          theme: "grid",
          styles: { fontSize: 4 },
          tableWidth: "auto",
          columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
        });
        pdf.output("dataurlnewwindow");
        pdf.save("CallerReport" + from + "_to_" + to + ".pdf");
      });
  }


}
  