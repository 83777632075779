import { element } from 'protractor';
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ReportService } from "src/app/elastic-report/report.service";
import Swal from 'sweetalert2';

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.css"],
})
export class CommentsComponent implements OnInit {
  @Output() refreshReport = new EventEmitter<void>();
  @Input() public commentopen: Boolean;
  @Input() public comment: any;
  @Input() public call_id: any;
  @Input() public id: any;
  @Output() public CloseComment = new EventEmitter<void>();
  commentList=[]
  activeTab: 'addComment' | 'commentHistory' = 'addComment'


  comment_error: string = "";

  constructor(private reportservice: ReportService) {
    if (this.commentopen) {
      console.log("comment ipen");
    }
  }

  ngOnInit() {
    this.commentList=[]
  }

  close() {
    this.comment_error = "";
    this.comment = "";
    this.CloseComment.emit();
    this.commentList=[];
    this.activeTab ='addComment'


  }


  loadFollowUpList() {
    if (this.activeTab === 'commentHistory') {
      console.log("========LOAD PTREVIOUS COMMENTS======",this.call_id)
      this.reportservice.getcommentHistory(this.id, this.call_id).subscribe(
        (data: any) => {
          if (data.status = 1){
            this.commentList = data.commenthistory
          }
          
        }
      )
    }
  }

  switchTab(tab) {
    this.activeTab = tab
    if (tab === 'commentHistory') {
      // this.editMode = false
      this.loadFollowUpList()
    }
    else{
      // this.clearFormFields()
      // this.editMode = false
    }
  }

  SubmitComment() {
    if (!this.comment || this.comment.trim() === '' || this.comment === 'Click to Add Comment') {
      Swal.fire({
        title: 'Validation Error',
        text: 'Please enter a comment before submitting.',
        type: 'warning', 
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      });
      return;
    }
    this.reportservice
      .submitcomment(this.comment, this.call_id, this.id)
      .subscribe((res: any) => {
        if (res.status == 1) {
          let com_string = "";

          let elementId = this.call_id

          if(this.call_id == null){
            elementId = this.id
          }

          console.log("Element Id   : ",elementId)

          let element = document.getElementById(elementId);

          if (this.comment) {
            com_string = this.comment;

            if (com_string.length > 300) {
              console.log("greter tahn 300");
              // if (element.getAttribute('class'))
              // element.setAttribute('class', 'trim-text');
            }

            element.style.color = "";
          } else {
            com_string = "Click to Add Comment";
            element.style.color = "gray";
          }
          document.getElementById(elementId).innerHTML = com_string;
          this.refreshReport.emit();   // Table refresh
          this.close();
        } else {
          this.comment_error = res.message;
        }
      });
  }
}
