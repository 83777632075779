import { NotificationService } from 'src/app/notification/notification.service';
import { Component, OnInit ,Output} from '@angular/core';
import { saveAs } from 'file-saver';
import { ReportService } from '../inbound-report/report.service';
import { FormGroup, Validators, FormControl } from '@angular/forms'; 
import * as moment from 'moment'
import { API_URL, VOICE_URL } from 'src/global';
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import { ExcelService } from '../inbound-report/excel.service';
import { DatePipe } from '@angular/common';
import { LoginService } from '../login.service';
import Swal from 'sweetalert2';
import 'jspdf-autotable'
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { templateJitUrl } from '@angular/compiler';
import { element } from 'protractor';
import { count } from 'console';
import { resolve } from 'url';
import { ReportSettingsService } from '../report-settings/report-settings.service';

@Component({
  selector: 'app-callback-report',
  templateUrl: './callback-report.component.html',
  styleUrls: ['./callback-report.component.css'],
  providers: [DatePipe]
})
export class CallbackReportComponent implements OnInit {

  CallbackReportData:any = new Array();
  CallbackVoiceReportData:any = new Array();
  wordarray:any = new Array();
  current_page:number=1;
  total_page:any;
  last:number;
  fromdate: string='';
  todate: string='';
  goPage: number;
  searchText: string='';
  userData: any;
  callStatus:string='';
  myDate = new Date();
  today = new Date(this.myDate.setDate(this.myDate.getDate()));
  today_informat = this.datePipe.transform(this.today, 'dd-MM-yyyy');
  ExcelPdfReportData:any = new Array();
  voice_url = VOICE_URL
  progress: number = 0;
  isShownprogressbar :boolean=false;
  userType:any;
  permissions:any;




  addresspopup:boolean=false;
  contact:any;
  contact_initial:any;

  commentopen:boolean=false;
  comment:any
  call_id:any;

  showModal:boolean=false;
  callId : string;

  tagList: any = new Array()
  tag_filter: any = ""
  showTagDiv: boolean = false

  defaultDateFrom: Date;
  defaultDateTo: Date;

  constructor(
    private reportservice: ReportService,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private loginservice: LoginService,
    private notifyService: NotificationService,
    private reportsettingservice: ReportSettingsService,
  ) { }

  ngOnInit() {
    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);

    this.userData = this.loginservice.getUserdata(true);
    this.permissions = this.userData.permissions
    this.userType=localStorage.getItem('userType')
    this.contact_initial = {'number':'', 'name':'', 'email':'', 'id':'', 'user':this.userData.id}
    this.contact = this.contact_initial
    this.getCallTags()
    this.FetchCallbackReportData(1);
  }


  onFromDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateFrom = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    }
  }
  onToDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateTo = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);
    }
  }
  
  FetchCallbackReportData(pageNo){
    var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');

    this.reportservice.getcallbackreport(this.userData.id, from, to, this.searchText, this.callStatus,pageNo,"",this.userType, this.tag_filter).subscribe(
      (res:any) => {
        this.CallbackReportData = res["results"]
        this.total_page = res['total_pages']
        this.current_page = res['current_page']
        this.last = res['total_pages']
      }
    )
  }


  searchBox(){
    this.FetchCallbackReportData(1)
  }

  getcallrecord(vffile,date) {
    var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');

    this.reportservice.getcallrecord(vffile,date).subscribe(
      (res: any) => {
        this.CallbackVoiceReportData = res["data"]
        this.reportservice.getcallbackreport(this.userData.id, from, to, this.searchText, this.callStatus, this.current_page, "", this.userType, this.tag_filter).subscribe(
          (res: any) => {
            this.CallbackReportData = res["results"]
            this.total_page = res['total_pages']
            this.current_page = res['current_pages']
            this.last = res['total_pages']
            this.notifyService.sendNotification('Downloaded', 'Download Voice successfully', 'is-success')

          }
        )
      }
    )
  }

  exportAsXLSX():void{

    var text;
    var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');

    this.reportservice.getcallbackreport(this.userData.id, from, to, this.searchText, this.callStatus, this.current_page, "yes", this.userType, this.tag_filter).subscribe(
      (res:any) => {
        this.ExcelPdfReportData = res["results"]
        for (let element of this.ExcelPdfReportData) {
          var objz = {'DID':element['DID'],'Customer':element['answeredAgent'], 'Country code':element['country_code'],'Customer Name':element['customer']['name'],
          'Date':element['legAstartTime'],'Agent Number':element['callerid'],
          'Agent Name':element['agentName'],'Duration':element['duration'],
          'Connect Duration':element['connectedDuration'],'comment':element['comment'],
          'Tag':element['tag'], 'Status':element['callStatus']}
          this.wordarray.push(objz)

        }

        if (this.fromdate, this.todate){
          text = "Callback Report from: "+from+" To: "+to+""
        }
        else{
          text = "Callback Report of "+ this.today_informat
        }
        this.excelService.exportAsExcelFile(this.wordarray, text);
        this.wordarray=[];
      }
    )
  }

  downloads(){
    var countSlNo = 1
    var newarray=[]
    var head = [['SlNo','DID', 'Agent', 'Agent Name', 'Date','Customer','Country code','Customer Name','Call Duration', 'Connected Duration','comment', 'Tag', 'Status']]
    var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');
    this.reportservice.getcallbackreport(this.userData.id, from, to, this.searchText, this.callStatus, this.current_page, "yes",this.userType, this.tag_filter).subscribe(
      (res:any) => {
        this.ExcelPdfReportData = res["results"]
        for (let element of this.ExcelPdfReportData) {
          newarray.push([countSlNo,element['DID'],element['callerid'],
          element['agentName'],element['legAstartTime'],element['answeredAgent'],element['country_code'],element['customer']['name'] || '',
          element['duration'],element['connectedDuration'],element['comment'], element['tag'], element['callStatus']])
          countSlNo = countSlNo + 1
      
        }
        var pdf = new jsPDF();
        pdf.text('Callback Report', 11, 8);
        pdf.setFontSize(3);
        pdf.setTextColor(100);
        (pdf as any).autoTable({
          head:head,
          body: newarray,
          theme: 'grid',
          styles:{fontSize:4},
          tableWidth: 'auto',
          columnStyles: {0:{ halign: 'center', fillColor: [0, 255, 0]}},
          margin: {top:10},
        })

        pdf.output('dataurlnewwindow')
        pdf.save('CallbackReport.pdf')
      }
    )
  }

  fetchNumber(i) {
    if(i == 'prev'){
      if (this.current_page > 1) {
        this.FetchCallbackReportData(this.current_page-1);
      }
    }

    else if (i=='next'){
      if (this.current_page < this.last){
        this.FetchCallbackReportData(this.current_page+1);
      }
    }
    
    else if(i == 'first'){
      this.FetchCallbackReportData(1);
    }

    else if(i == 'last'){
      this.FetchCallbackReportData(this.last);
    }

    else{

      if (parseInt(i)===parseInt(i,10) && i > 0 && i <= this.last){
        this.FetchCallbackReportData(i);

      }
      else{
        Swal.fire('Oops..!','Enter Valid Number', 'warning');
      }
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  DownloadallVoice(){
    var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');

    this.reportservice.getcallbackreport(this.userData.id, from, to, this.searchText, this.callStatus, this.current_page, "voice_download_yes",this.userType, this.tag_filter).subscribe((event:HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:  
        this.isShownprogressbar=true;
        console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        console.log('Response header has been received!');
        break;
      case HttpEventType.DownloadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        console.log('User successfully created!', event.body);
        var a = document.createElement('a');
        var blob = new Blob([ event.body], {'type':"application/zip"});
        a.href = URL.createObjectURL(blob);
        if (from && to){
          a.download = from+'_'+to+".zip";
          }
        else{
          a.download = "Callback"+this.today_informat+".zip";
        }
        a.click();      
        setTimeout(() => {
          this.progress = 0;
          this.isShownprogressbar=false;
        }, 1500);
      }
    })
  }


  async onNavigate(){
    var url_array = []
    for (let element in this.CallbackReportData){
      var x = this.CallbackReportData[element]
      if(x['callStatus'] == "ANSWERED"){
        if(x['callRecordVF'] !== ""){
          var voice_date=this.datePipe.transform(x['legAstartTime'], 'yyyy-MM-dd');
          let url="http:"+this.voice_url+voice_date+"/"+x['callRecordVF']+"";

          url_array.push(url)
          var link = document.createElement("a");
          link.download = "a";
          link.href = url;
          link.download=url;
          link.click();
          await this.delay(3000);
        } 
      }
    }
    Swal.fire('Success', 'Downloaded Successfully', 'success')
  }


  LoadPage(){
    var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');
    this.reportservice.getcallbackreport(this.userData.id, from, to,this.searchText,this.callStatus,this.current_page,"",this.userType, this.tag_filter).subscribe(
      (res:any) => {
        this.CallbackReportData = res["results"]
        this.total_page =  res['total_pages']
        this.current_page = res['current_page']
        this.last = res['total_pages']
      })
  }

  closeContact(){
    this.addresspopup=false
    this.contact = this.contact_initial
  }

  openContact(mobile,id){
    this.addresspopup=true
    this.contact.number=mobile
    if(id){
      this.reportservice.getcontact(id,this.userData.id,this.userType).subscribe(
        (res:any) => {
          if(res.id){
            this.contact=res
          }
          else{
            this.notifyService.sendNotification('','Error in Getting conatct', 'is-danger')
          }
        });
    }
  }

  CloseComment(){
    this.commentopen = false
    this.call_id=""
    this.comment =""
  }
  
  updateReportPage() {
    this.FetchCallbackReportData(this.current_page);
  }

  addCallRecordTag(call_record_id: any, index: any) {
    this.reportsettingservice.getReportTagChild(call_record_id).subscribe((res: any) => {
      const updatedTags = res.tag.split(',').map(tag => tag.trim());
      this.CallbackReportData[index].tag = updatedTags.join(', ');
    })
  }

  deleteCallRecordTag(call_record_id: any, tag: any, index: any) {
    const body = {
      tag: tag
    }

    this.reportsettingservice.deleteReportTagChild(call_record_id, body).subscribe((res: any) => {
      this.CallbackReportData[index].tag = res.tags.tag
    })
  }

  getCallTags() {
    let user_id: any
    if (this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser') {
      user_id = this.userData.admin;
    } else {
      user_id = this.userData.id;
    }

    this.reportsettingservice.getReportTagMain(user_id).subscribe((res: any) => {
      this.tagList = res.report_tags
    })
  }
}
