import { Component, OnInit } from '@angular/core';
import { CallCountReportService } from './call-count-report-service';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../inbound-report/excel.service';
import * as jsPDF from "jspdf";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import Swal from 'sweetalert2';
import { API_URL } from 'src/global';

@Component({
  selector: 'app-call-count-report',
  templateUrl: './call-count-report.component.html',
  styleUrls: ['./call-count-report.component.css'],
  providers: [DatePipe],
})
export class CallCountReportComponent implements OnInit {

  constructor(
    private callCountReportService: CallCountReportService,
    private datePipe: DatePipe,
    private excelService: ExcelService,
    private spinnerService: Ng4LoadingSpinnerService,
  ) { }

  CallCountReportData: any;
  callDetailData: any;
  showModal: boolean = false;
  pagination = { current_page: 1, total_page: 0 };
  fromdate: String;
  todate: String;
  user_data: any;
  payload = [];
  ExcelPdfReportData = [];
  wordarray: any = new Array;
  selectAgentNumber: string = "";
  selectedDetailType: string = "";

  defaultDateFrom: Date;
  defaultDateTo: Date;



  ngOnInit() {

    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);


    this.user_data = JSON.parse(localStorage.userdata);
    this.generateReportTable();

  }


  onFromDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateFrom = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    }
  }
  onToDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateTo = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);
    }
  }
  
  generateReportTable() {

    if (this.fromdate != "" || this.todate != "") {
      var from_date = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
      var to_date = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");
    }

    const data = {
      "scope": "report",
      "user_id": this.user_data.id,
      "user_type": this.user_data.usertype,
      "from_date": from_date,
      "to_date": to_date
    }

    this.spinnerService.show();
    this.callCountReportService.getCallCountReport(data)
      .subscribe((res: any) => {
        if (res.status === "1") {
          this.CallCountReportData = res.results
        } 
        this.spinnerService.hide();
      })
  }




  paginate(event) {
    if (event == "first") {
      this.pagination.current_page = 1;
      this.onCountDetail(null, this.selectAgentNumber, this.selectedDetailType);
    }

    if (event == "last") {
      this.pagination.current_page = this.pagination.total_page;
      this.onCountDetail(null, this.selectAgentNumber, this.selectedDetailType);
    }

    if (
      event == "next" &&
      this.pagination.current_page < this.pagination.total_page
    ) {
      this.pagination.current_page += 1;
      this.onCountDetail(null, this.selectAgentNumber, this.selectedDetailType);
    }

    if (event == "prev" && this.pagination.current_page != 1) {
      this.pagination.current_page -= 1;
      this.onCountDetail(null, this.selectAgentNumber, this.selectedDetailType);
    }
  }

  fetchByPageNumber(num) {
    if (parseInt(num) === parseInt(num, 10) && num > 0 && num <= this.pagination.total_page) {
      this.pagination.current_page = parseInt(num);
      this.onCountDetail(null, this.selectAgentNumber, this.selectedDetailType)
    }
    else {
      Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
    }

  }

  exportAsXLSX() {




    if (this.CallCountReportData) {
      var text;
      var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
      var to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");


      this.CallCountReportData.forEach(( element, index) => {
        var objz = {
          "Sl No": index + 1,
          "Agent Name": element["name"],
          "Agent Number": element["agent_number"],

          "Total Call Duration(Mins)": element["callduration_min"],
          "Total Call Duration(Sec)": element["callduration_sec"],

          "Total Call Attempts": (element["incoming"]["total"] || 0) + (element["outgoing"]["total"] || 0),
          "Total Connected Calls": (element["incoming"]["answered_count"] || 0) + (element["outgoing"]["answered_count"] || 0),
          "Total Missed Calls": (element["incoming"]["notanswered_count"] || 0) + (element["outgoing"]["notanswered_count"] || 0),


          "In: Call Attempts": (element["incoming"]["total"]) || 0,
          "In: Duration(Sec)": (element["incoming"]["callduration"]) || 0,
          "In: Connected Calls": (element["incoming"]["answered_count"]) || 0,
          "In: Missed Calls": (element["incoming"]["notanswered_count"]) || 0,
          "In: Unique Calls": (element["incoming"]["unique_calls"]) || 0,
          "In: Call Backs": (element["incoming"]["callbacks"]) || 0,

          "Out: Call Attempts": (element["outgoing"]["total"]) || 0,
          "Out: Duration(Sec)": (element["outgoing"]["callduration"]) || 0,
          "Out: Unique Calls": (element["outgoing"]["unique_calls"]) || 0,
          "Out: Connected Calls": (element["outgoing"]["answered_count"]) || 0,
          "Out: Missed Calls": (element["outgoing"]["notanswered_count"]) || 0,

        };

        this.wordarray.push(objz);
      });


      if ((this.fromdate, this.todate)) {
        text = "Call Count Report from: " + from + " To: " + to + "";
      } else {
        text = "Call Count Report";
      }

      this.excelService.exportAsExcelFile(this.wordarray, text);
      this.wordarray = [];
    }
  }

  downloads() {

    if (this.CallCountReportData) {
      var countSlNo = 1;
      var newarray = [];
      var head = [
        [
          "Sl No",
          "Agent Name",
          "Agent Number",
          "Total Call Duration(Mins)",
          "Total Call Duration(Sec)",
          "Total Call Attempts",
          "Total Connected Calls",
          "Total Missed Calls",

          "In: Call Attempts",
          "In: Duration(Sec)",
          "In: Unique Calls",
          "In: Call Backs",
          "In: Connected Calls",
          "In: Missed Calls",

          "Out: Call Attempts",
          "Out: Duration(Sec)",
          "Out: Unique Calls",
          "Out: Connected Calls",
          "Out: Missed Calls"

        ],
      ];
      var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
      var to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");


      this.CallCountReportData.forEach((element, index) => {
        
        newarray.push([
          index + 1,
          element["name"],
          element["agent_number"],
          element["callduration_min"],
          element["callduration_sec"],
          (element["incoming"]["total"] || 0) + (element["outgoing"]["total"] || 0),

          (element["incoming"]["answered_count"] || 0) + (element["outgoing"]["answered_count"] || 0),

          (element["incoming"]["notanswered_count"] || 0) + (element["outgoing"]["notanswered_count"] || 0),

          (element["incoming"]["total"]) || 0,
          (element["incoming"]["callduration"]) || 0,
          (element["incoming"]["unique_calls"]) || 0,
          (element["incoming"]["callbacks"]) || 0,
          (element["incoming"]["answered_count"]) || 0,
          (element["incoming"]["notanswered_count"]) || 0,

          (element["outgoing"]["total"]) || 0,
          (element["outgoing"]["callduration"]) || 0,
          (element["outgoing"]["unique_calls"]) || 0,
          (element["outgoing"]["answered_count"]) || 0,
          (element["outgoing"]["notanswered_count"]) || 0

        ]);

      });






      var pdf = new jsPDF();
      pdf.text("Call Count Report", 11, 8);
      pdf.setFontSize(3);
      pdf.setTextColor(100);
      (pdf as any).autoTable({
        head: head,
        body: newarray,
        theme: "grid",
        styles: { fontSize: 4 },
        tableWidth: "auto",
        columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
        margin: { top: 10 },
      });
      pdf.output("dataurlnewwindow");
      pdf.save("Call Count Report" + from + "_to_" + to + ".pdf");
      this.spinnerService.hide();
    }
  }

  onCountDetail(event: Event | null, number, type) {
    if (event) {
      event.preventDefault();      
    }
    
    this.selectAgentNumber = number;
    this.selectedDetailType = type;
    
    const data = {
      scope: "detail",
      user_id: this.user_data.id,
      user_type: this.user_data.usertype,
      agent_number: number,
      from_date: this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss"),
      to_date: this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss")
    }
    if (type == "total_answered_count") {
      data["is_answered"] = true;
    } else if(type == "total_notanswered_count") {
      data["is_answered"] = false;
     } else if (type == "incoming_total_count") {
      data["direction"] = "incoming";
    } else if (type == "incoming_unique") {
      data["direction"] = "incoming";
      data["is_unique"] = true;
     } else if (type == "incoming_callback") {
      data["direction"] = "incoming";
      data["is_callback"] = true;
    } else if (type == "incoming_answered") {
      data["direction"] = "incoming";
      data["is_answered"] = true;
     } else if (type == "incoming_notanswered") {
      data["direction"] = "incoming";
      data["is_answered"] = false;
     } else if (type == "outgoing_total_count") {
      data["direction"] = "outgoing";
    } else if (type == "outgoing_unique") {
      data["direction"] = "outgoing";
      data["is_unique"] = true;
    } else if (type == "outgoing_answered") {
      data["direction"] = "outgoing";
      data["is_answered"] = true;
    } else if (type == "outgoing_notanswered") {
      data["direction"] = "outgoing";
      data["is_answered"] = false;
    }

    this.spinnerService.show();

    this.callCountReportService.getCallCountDetail(data, this.pagination.current_page).subscribe((res: any) => {
      if (res.status === "1") {
        this.callDetailData = res.results;
        this.pagination.total_page = res.total_pages;
        this.showModal = true;
        this.spinnerService.hide();
      } else {
        this.spinnerService.hide();
        Swal.fire("Something went wrong!", res.error, "warning")
      }
    })
    

  }

  closeModal() {
    this.showModal = false;
    this.pagination.current_page = 1;
    this.callDetailData = [];
  }

  getFormattedDate(inputDate: string): string {
      const date = new Date(inputDate);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }
  
  getFormattedTime(minutes: number): string {
    const totalSeconds = Math.floor(minutes * 60);
    const hours = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    const hoursStr = hours.toString().padStart(2, '0');
    const minsStr = mins.toString().padStart(2, '0');
    const secsStr = secs.toString().padStart(2, '0');
    return `${hoursStr}:${minsStr}:${secsStr}`; 
 }

  getFormattedDuration(seconds: string): number {
    const duration = parseInt(seconds)
    if (duration) {
      return duration
    } else {
      return 0
    }
  }


}
