import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { SharedService } from '../../global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

	constructor(
		private http:HttpClient,
		public sharedService: SharedService

	) { }

	API_URL = this.sharedService.getGlobalVar()

	header = new HttpHeaders().set("Content-Type", "application/json");

	getDidList(id: number) {
		return this.http.get(this.API_URL + 'did_list/' + id, { headers: this.header })
	}
	
	getlasttencalls(body){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'lasttencalls/',body,{headers:headers})
	}

	getDashboardData(body,userProfileId){
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.post(this.API_URL+'dashboard_count_data/'+userProfileId+"/",body,{headers:headers})
	
	}

	getDashboardCallLog(body: any, userProfileId: string, page=1): Observable<any> {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		
		// Perform the HTTP POST request and return the observable
		return this.http.post<any>(this.API_URL + 'all_call_log/' + userProfileId + '/?page='+page, body, { headers: headers });
	  
	}

	getAgentBarChartData(body: any, userProfileId: string): Observable<any>{
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
				return this.http.post<any>(this.API_URL + 'agent_call_count_chart/' + userProfileId +"/", body, { headers: headers });
	
			}


	getLiveCallDetails(body:any,userProfileId: string): Observable<any>{
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
				return this.http.post<any>(this.API_URL+"LiveCallList/"+userProfileId+"/", body, { headers: headers });
	
	}


	getCallCountReport(body: any, userProfileId: string): Observable<any>{
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
				return this.http.post<any>(this.API_URL + 'call_status_list/' + userProfileId +"/", body, { headers: headers });
	
			}

	getCallCountLineChart(body: any, userProfileId: string){
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
				return this.http.post<any>(this.API_URL + 'agent_call_month_count_chart/' + userProfileId +"/", body, { headers: headers });
	}
}
