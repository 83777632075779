import { Component, OnInit } from '@angular/core';
import { PreloaderService } from './preloader.service';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.css']
})
export class PreloaderComponent implements OnInit {
  isLoading = false;
  constructor(private preloaderService: PreloaderService) { }

  ngOnInit() {
    this.preloaderService.loading$.subscribe(
      (isLoading: boolean) => {
        this.isLoading = isLoading;
      }
    );
    console.log("preloader working")
  }

}
