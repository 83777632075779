import { Component, OnInit } from '@angular/core';
import { LoginService } from "../../login.service";
import { SmsService } from 'src/app/sms/sms.service';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
import { DatePipe } from '@angular/common';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import * as jsPDF from 'jspdf';
import {ExcelConvertService} from './excel-convert.service';


@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.css'],
  providers: [DatePipe]

})

export class ViewReportComponent implements OnInit {
  public userData:any;
  public datas:any;
  public body:any;
  permissions:any;
  date1:string;
  searchData:any;
  current_page:any;
  total_page:any;
  last_page:any;
  permis = false;
  fromdate:any;
  todate:any;
  reportForm:FormGroup;

  ExcelPdfReportData:any = new Array();
  wordarray:any =new Array();
  myDate = new Date();
  today =  new Date(this.myDate.setDate(this.myDate.getDate()));
  today_informat = this.datePipe.transform(this.today, 'yyyy-MM-dd');

  defaultDateFrom: Date;
  defaultDateTo: Date;

  constructor(
    private loginService: LoginService,
    private smsService: SmsService,
    private notifyservice: NotificationService,
    private datePipe: DatePipe,
    private excelService:ExcelConvertService,

  ) { }

  ngOnInit() {
    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);

    this.userData = this.loginService.getUserdata(true);
    this.permissions = this.userData.permissions
      for (let element of this.permissions) {
        if(element.codename == "cadmin_s_viewReport"){
          this.permis = true;
        }
      }

      this.fetchData(1)
      this.reportForm = new FormGroup({
        toDate: new FormControl(''),
        fromDate: new FormControl(''),
        user: new FormControl(0),
      });

    this.reportForm.get('fromDate').valueChanges.subscribe((value) => {
      this.onFromDateChange(value);
    });

    this.reportForm.get('toDate').valueChanges.subscribe((value) => {
      this.onToDateChange(value);
    });

}

onFromDateChange(value: any): void {
  if (value && value !== "") {
    const formattedDate = this.datePipe.transform(value, "yyyy-MM-dd HH:mm:ss");
    this.defaultDateFrom = new Date(formattedDate);
    console.log('From Date Changed:', formattedDate);
  } else {
    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    console.log('From Date Reset to Start of Day:', this.defaultDateFrom);
  }
}

onToDateChange(value: any): void {
  if (value && value !== "") {
    const formattedDate = this.datePipe.transform(value, "yyyy-MM-dd HH:mm:ss");
    this.defaultDateTo = new Date(formattedDate);
    console.log('To Date Changed:', formattedDate);
  } else {
    const defaultDate = new Date();
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);
    console.log('To Date Reset to End of Day:', this.defaultDateTo);
  }
}


fetchData(pageNumberData){
  if(this.date1 == "Invalid date" || this.date1 == undefined){
    this.fromdate = this.datePipe.transform(new Date(), 'yyyy-MM-dd 00:00:00');
    this.todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd 23:59:59');
  }
  else{
    this.fromdate = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss")
    this.todate = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss")
    console.log(this.fromdate,"**********",this.todate)
  }


    // this.body = {"userId":this.userData.id,"from":this.fromdate,"to":this.todate,"search":this.searchData}
    var body = {"userId":this.userData.id,"from":this.fromdate,"to":this.todate,"search":this.searchData}
    this.smsService.load_sms_report_by_page(body,pageNumberData).subscribe((res:any) =>{
      this.current_page = res.current_page;
      this.datas = res.results;
      this.last_page=res.total_pages;
    }

)



}


fetchByPageNumber(pagenumber){
  this.fetchData(pagenumber)
}

fetchPreviousPage(){
  this.fetchData(this.current_page - 1)

}

fetchFirstPage(){
  this.fetchData(1)

}

fetchNextPage(){
  this.fetchData(this.current_page + 1)

}

fetchLastPage(){
  this.fetchData(this.last_page)
}

searchBox(){
  this.fetchData(1)
}

SearchReport(){
  this.fetchData(1)
}


            exportAsXLSX():void {
              this.fetchData(1)
              var text;
              var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
              var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');
              
              if(this.date1 == "Invalid date" || this.date1 == undefined){
                this.fromdate = this.datePipe.transform(new Date(), 'yyyy-MM-dd 00:00:00');
                this.todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd 23:59:59');
              }
              else{
                this.fromdate = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss")
                this.todate = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss")
                console.log(this.fromdate,"**********",this.todate)
              }


              var body = {"userId":this.userData.id,"from":this.fromdate,"to":this.todate,"search":this.searchData}
              this.smsService.load_sms_report_without_page(body,this.total_page).subscribe((res:any) =>{
                  this.datas = res;
                  this.total_page = res;
              this.ExcelPdfReportData = this.datas
              for (let element of this.ExcelPdfReportData) {
                var objz = {'phoneNumber':element['phoneNumber'],'DID':element.DID['name'],'status':element['status'],
                            'sendingTime':element['sendingTime'],'deliveryTime':element['deliveryTime']}
                this.wordarray.push(objz)
                }
              if (this.fromdate, this.todate){
                  text = "View Report from: "+from+" To: "+to+""
                }
              else{
                  text = "View Report of "+ this.today_informat
                }
              this.excelService.exportAsExcelFile(this.wordarray,text);
              this.wordarray=[];
            })

            }
}



