import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { InboundRouteService } from '../inbound-route.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-api-template',
  templateUrl: './api-template.component.html',
  styleUrls: ['./api-template.component.css']
})
export class ApiTemplateComponent implements OnInit, AfterViewInit {

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @Output() customerEmitter: any = new EventEmitter();

  userData: any;
  userListReadOnly: boolean = false;
  customerInitial: string = "";
  customerId: string | null = null;
  showModel: boolean = false;
  modelHeader: string = "Add new API template";
  templateForm: FormGroup;
  isCollapsed: boolean = true;
  scrolledToBottom: boolean = false;
  templateId: string | null = null;
  dataTable: any;
  settingsList: any[] = [];
  duplicatesExists: boolean = false;
  duplicatesIn: string = "";


  defaultHeaderArray: any[] = [
    { key: "Content-Type", value: "application/json" }
  ]
  defaultBodyArray: any[] = [
    { param: '', value: "did" },
    { param: '', value: "from" }
  ];

  responseFormat: any = {
    status: "1",
    destination: "9846098460"
  }



  constructor(
    private loginService: LoginService,
    private fb: FormBuilder,
    private irService: InboundRouteService
  ) {
    this.templateForm = this.fb.group({
      name: ['', Validators.required],
      endpoint: ["", [Validators.required, Validators.pattern(/^(https?:\/\/)[^\s$.?#].[^\s]*$/)]],
      method: ["post"],
      headers: this.fb.array([]),
      body: this.fb.array([])
    })
  }

  ngOnInit() {

    this.userData = this.loginService.getUserdata(false)
    this.intializeUserSettings();
    this.initializeControls();
    this.initializeDatatable();

  }

  ngAfterViewInit(): void {
    this.updateDataTable();
  }


  intializeUserSettings() {
    if (this.userData.usergroup != "superadmin") {
      this.userListReadOnly = true;
      this.customerInitial = this.userData.name;
      this.customerId = this.userData.id;
    }
  }

  customerEvent(userId) {
    this.customerId = userId;
    this.updateDataTable();
  }

  initializeControls() {

    for (let item of this.defaultHeaderArray) {
      const headerGroup = this.fb.group({
        key: [item.key, Validators.required],
        value: [item.value, Validators.required]
      })
      headerGroup.get("key").disable();
      headerGroup.get("value").disable();
      this.headers.push(headerGroup)
    }

    for (let item of this.defaultBodyArray) {
      const bodyGroup = this.fb.group({
        param: [item.param, Validators.required],
        value: [item.value, Validators.required],
      });
      bodyGroup.get('value').disable();
      this.body.push(bodyGroup);
    }

  }


  onModelClose() {
    this.showModel = false;
    this.templateId = null;
    this.templateForm.reset();
    this.templateForm.patchValue({ method: 'post' });

    while (this.headers.length != 0) {
      this.headers.removeAt(0);
    }

    while (this.body.length != 0) {
      this.body.removeAt(0);
    }

    this.initializeControls();

  }


  get headers(): FormArray {
    return this.templateForm.get('headers') as FormArray;
  }

  get body(): FormArray {
    return this.templateForm.get('body') as FormArray;
  }

  onAddHeaders() {
    const headerGroup = this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required],
    });
    this.headers.push(headerGroup);

  }

  onAddBody() {
    const bodyGroup = this.fb.group({
      param: ['', Validators.required],
      value: ['', Validators.required],
    });
    this.body.push(bodyGroup);
  }

  removeHeader(index: number) {
    this.headers.removeAt(index);
    const formData = this.templateForm.getRawValue()
    if (this.validateDuplicate(formData.headers)) {
      this.duplicatesExists = true;
      this.duplicatesIn += "Headers";
    } else {
      this.duplicatesExists = false;
      this.duplicatesIn.replace("Headers", "")
    }
  }

  removeBody(index: number) {
    this.body.removeAt(index);
    const formData = this.templateForm.getRawValue()
    if (this.validateDuplicate(formData.body)) {
      this.duplicatesExists = true;
      this.duplicatesIn += "Body";
    } else {
      this.duplicatesExists = false;
      this.duplicatesIn.replace("Body", "")
    }

  }

  onSubmit() {
    this.markFormGroupAsTouched(this.templateForm);
    this.markFormArrayAsTouched();
    if (this.templateForm.valid) {
      const formData = this.templateForm.getRawValue()
      this.duplicatesIn = "";

      if (this.validateDuplicate(formData.headers)) {
        this.duplicatesExists = true;
        this.duplicatesIn += "Headers";
      }

      if (this.validateDuplicate(formData.body)) {
        this.duplicatesExists = true;
        if(this.validateDuplicate(formData.headers)){
          this.duplicatesIn += ", Body";
        } else {
          this.duplicatesIn += "Body";
        }
        
      }

      if (this.duplicatesExists) {
        return;
      }




      const payload = {
        id: this.templateId,
        name: formData.name,
        url: formData.endpoint,
        methode: formData.method,
        header_format: formData.headers,
        data_format: formData.body,
        user: this.customerId
      }
      if (this.templateId) {
        this.irService.updateAPITemplate(payload).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Settings updated successfully.", "success");
            this.postSave();
          } else {
            if (!res.flash) {
              Swal.fire("Something went wrong!", "Failed to update settings", "error");
            } else {
              Swal.fire("Something went wrong!", res.message, "error");
            }
          }
        })
      } else {
        this.irService.addAPITemplate(payload).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Settings created successfully.", "success");
            this.postSave();
          } else {
            if (!res.flash) {
              Swal.fire("Something went wrong!", "Failed to update settings", "error");
            } else {
              Swal.fire("Something went wrong!", res.message, "error");
            }
          }
        });

      }

    }
  }

  postSave() {
    this.showModel = false;
    this.templateForm.reset();
    this.updateDataTable();
    this.templateForm.patchValue({ method: 'post' });
  }

  validateDuplicate(data: any[]): boolean {
    const uniqueItems = new Set(data.map(item => JSON.stringify(item)));
    return uniqueItems.size !== data.length;
  }

  toggleCollapse(el: HTMLElement) {
    this.isCollapsed = !this.isCollapsed;
  }

  get formattedResponse(): string {
    return JSON.stringify(this.responseFormat, null, 2);
  }



  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  markFormArrayAsTouched(): void {
    this.body.controls.forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      }
    });

    this.headers.controls.forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      }
    });
  }

  initializeDatatable() {
    this.dataTable = $('#settingsTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { data: 'slno', title: 'Sl No' },
        { data: 'username', title: 'User Name' },
        { data: 'name', title: 'Template Name' },
        { data: 'methode', title: 'Method', render: (data, type, row) => { return data.toUpperCase(); } },
        { data: 'url', title: 'Endpoint' },
        { data: 'action', title: "Action" }
      ]
    });
  }


  updateDataTable() {
    const qParams = [
      { key: "scope", value: "api_template" },
      { key: "user_id", value: this.customerId }
    ]

    this.irService.getAPITemplates(qParams).subscribe((res: any) => {
      if (res.status === "1") {
        this.settingsList = res.data.map((item, index) => ({
          ...item,
          slno: index + 1,
          action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                    <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`,
          status: `<input type="checkbox" ${item.active ? "checked" : ""} disabled/>`
        }))
        this.dataTable.clear();
        this.dataTable.rows.add(this.settingsList);
        this.dataTable.draw();

        $('#settingsTable tbody').on('click', '#btn-delete', (event: any) => {

          const row = this.dataTable.row($(event.target).closest('tr')).data();
          if (row) {
            this.deleteSettings(row.id);
          }
        });

        $('#settingsTable tbody').on('click', '#btn-edit', (event: any) => {

          const row = this.dataTable.row($(event.target).closest('tr')).data();
          if (row) {
            this.editSettings(row);
          }
        });
      }
    })
  }

  deleteSettings(id) {
    Swal.fire({
      title: "Are you sure ?",
      text: "The settings will be deleted",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete.",
      cancelButtonText: "No, Don't delete.",
    }).then((res) => {
      if (!res.dismiss) {
        this.irService.deleteAPITemplate(id).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Settings deleted successfully.", "success");
            this.updateDataTable();
          } else {
            Swal.fire("Something went wrong", "Failed to delete settings", "error");
          }
        })
      }
    })
  }

  editSettings(row) {

    this.templateId = row.id;
    this.showModel = true;
    this.templateForm.patchValue({
      name: row.name,
      endpoint: row.url,
      method: row.methode,
    });

    while (this.headers.length != 0) {
      this.headers.removeAt(0);
    }

    while (this.body.length != 0) {
      this.body.removeAt(0);
    }

    row.header_format.forEach(item => {
      const headerGroup = this.fb.group({
        key: [item.key, Validators.required],
        value: [item.value, Validators.required],
      });

      const headerExists = this.defaultHeaderArray.some(
        (header) => header.key === item.key && header.value === item.value
      );

      if (headerExists) {
        headerGroup.get('key').disable();
        headerGroup.get('value').disable();
      }
      this.headers.push(headerGroup);
    });

    row.data_format.forEach(item => {
      const bodyGroup = this.fb.group({
        param: [item.param, Validators.required],
        value: [item.value, Validators.required],
      });

      const bodyExists = this.defaultBodyArray.some(
        (body) => body.value === item.value
      );

      if (bodyExists) {
        bodyGroup.get('value').disable();
      }


      this.body.push(bodyGroup);

    });
  }
}
