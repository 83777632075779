import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { SharedService } from "src/global";

@Injectable({
    providedIn:"root"
})
export class SendDataIntegrationService{
    constructor(
        private http: HttpClient,
        public sharedService: SharedService
    ) { }
    API_URL = this.sharedService.getGlobalVar();
    header = new HttpHeaders().set("Content-Type", "application/json");

    getFormatFields(formatName: string) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.get(this.API_URL + `router/send-data/format_fields/?format_name=${formatName}`,{headers:headers})
    }

    saveData(data: any) {
        
        return this.http.post(this.API_URL + 'router/send-data/', data, { headers: this.header }); // Send a POST request to save data
    }
    getDataList(search) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        let params = new HttpParams().set('search', search);
        
        return this.http.get(this.API_URL + 'router/send-data/',{headers:headers,params:params})
    }

    updateData(id:number, data:any) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.put(this.API_URL + `router/send-data/${id}/`, data, { headers: headers });
    }

    getDataById(id:number) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.get(this.API_URL+ `router/send-data/${id}/`,{headers:headers})
    }

    deleteDataById(id:number) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.delete(this.API_URL + `router/send-data/${id}/`,{headers:headers})
    }

}