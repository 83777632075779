import { Component, OnInit } from '@angular/core';
import { QueuemanagementService } from "../../queuemanagement.service";
import { LoginService } from "../../login.service";
import Swal from 'sweetalert2';
import { NotificationService } from "../../notification/notification.service";
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { VoiceRecordService } from '../../voice-record/voice-record.service';
import { IvrService } from 'src/app/ivr/ivr.service';
import { InboundRouteService } from '../../inbound-route/inbound-route.service';
import { ExtensionService } from 'src/app/extension/extension.service';
import { UsermanagementService } from 'src/app/usermanagement/usermanagement.service';
import { API_URL, VOICE_URL } from 'src/global';
import { VoicemailService } from 'src/app/voicemail/voicemail.service';

@Component({
	selector: '[app-addqueue]',
	templateUrl: './addqueue.component.html',
	styleUrls: ['./addqueue.component.css']
})

export class AddqueueComponent implements OnInit {
	isCollapsed: boolean = true;
	submitted = false;
	keyword:string = 'name';
	keyword1:string = 'name';
	keyword2:string = 'name';
	public MusicOnHoldLabel:string = 'Music on Hold'
	public queueName:string;
	public description:string; 
	public agents:string = '';
	public agentNumbers:string;
	public agentNumbersList:any;
	public ringStrategy:string = 'random';
	public musiconhold:string;
	public failoverDestination:string;
	public failoverDestinationType:number;
	public smsalert:boolean;
	public emailalert:boolean;
	public callRecording:boolean =true;
	public stickyAgent: boolean;
	public stickyAgentStrategy: string;
	public syncCrm: boolean;
	public maxWaitTime:number = 120;
	public maxcallers:number = 5;
	public agentTimeOut:number = 30;
	public retryInterval:number = 10
	public agentAnnouncement:string;
	public userData:any;
	public isAdmin:string = 'false'
	public UserProfileId:number = 0;
	public id:any = 0;
	public QueueForm:FormGroup;
	public x:number;
	public y:number;
	permissions:any;
    permis = false;
	destiny: any;
	destination: string;
	destinyLabel: string;
	timeSetId: any;
	destinationList: any;
	VoiceAnnouncementLabel:string = 'Voice Announcement'
	Optiontype: any=0;
	agentUser: any;
	users:any = new Array();
	agentUsersIds: any;
	agentUsersList: any;
	agentUsers: any[];
	VirtualSipUsersList: any;
	failOverDestinationType: string= '3';
	failoverDestinationInitail: any;
	voiceInitial:any;
	musiconholdInitial:any;
	public virtualSipAgents:string = '';
	voicelist:any = new Array();
	


	constructor(
		private queueservice:QueuemanagementService,
		private loginService:LoginService,
		private notifyService: NotificationService,
		private activeroute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private loginservice: LoginService,
		private voicerecordservice: VoiceRecordService,
		private router: Router,
		private ivrservice: IvrService,
		private inboundservice: InboundRouteService,
		private extensionservice: ExtensionService,
		private usermanagementService: UsermanagementService,
		private voicemailservice: VoicemailService,
	) { }

	ngOnInit() {
		this.agentUsers = []
		this.agentUsersIds = []
		// this.agentTimeOut = 0;
		// this.maxWaitTime = 120;
		this.userData = this.loginservice.getUserdata(true);
		this.permissions = this.userData.permissions
		for (let element of this.permissions) {
			if(element.codename == "cadmin_q_addQueue"){
				this.permis = true;
			}
		}
		this.QueueForm = new FormGroup({
			name: new FormControl(Validators.required,this.customPatternValid({ pattern: /^(\d|\w)+$/, msg: 'Space / special Charcaters(!#$%&()*+,-./:;<=>?) are not allowed' })),
			description: new FormControl(),
			failoverDestination:new FormControl(),
			ringStrategy:new FormControl('',Validators.required),
			musiconhold:new FormControl(),
			failOverDestinationType:new FormControl(),
			smsalert:new FormControl(),
			emailalert:new FormControl(),
			callRecording:new FormControl('',Validators.required),
			stickyAgent: new FormControl(),
			stickyIsStrict: new FormControl(false),
			syncCrm: new FormControl(),
			maxWaitTime:new FormControl(),
			maxcallers:new FormControl(),
			agentTimeOut:new FormControl(),
			retryInterval:new FormControl(),
			agentAnnouncement:new FormControl(),
			agentNumbers:new FormControl(),
			agentUsers:new FormControl(),
			agents: new FormControl(Validators.required),
			virtualSipAgents:new FormControl(),
			user: new FormControl(this.userData.id),

		  });

	



		this.isAdmin = localStorage.getItem('isAdmin')
		this.userData = this.loginService.getUserdata(false);
		this.id = +this.activeroute.snapshot.paramMap.get('id');
		if(this.id != 0) {
			if(this.isAdmin == 'false'){
				this.UserProfileId = this.userData.id
				this.QueueForm.controls['name'].disable();
			}

			this.queueservice.oneQueue(this.id).subscribe(
			(data:any) => {
				this.queueName = data.name
				this.description = data.description
				this.agents = data.agents
				this.ringStrategy = data.ringStrategy
				this.agentNumbers = data.agentNumbers
				var time = data.agentTimeOut
				var min = time.split(':')[1]*60;
				var sec = time.split(':')[2];
				this.agentTimeOut = Number(min)+Number(sec)
				this.musiconhold = data.musiconhold
				this.failOverDestinationType= data.failOverDestinationType;
				this.failoverDestination = data.failoverDestination
				this.failoverDestinationInitail = data.failoverDestination
				this.maxcallers = data.maxcallers
				this.smsalert = data.smsalert
				this.emailalert = data.emailalert
				this.callRecording = data.callRecording
				this.stickyAgent = data.stickyAgent				
				this.syncCrm = data.sync_crm
				var time1 = data.maxWaitTime
				var min = time1.split(':')[1]*60;
				var sec = time1.split(':')[2];
				this.maxWaitTime = Number(min)+Number(sec)
				this.retryInterval = data.retryInterval
				this.agentAnnouncement= data.agentAnnouncement
				this.userData = data.userData
				this.agentNumbersList=this.agentNumbers.split(',');
				this.QueueForm.patchValue({
					stickyIsStrict: data.stickyIsStrict
				})
			});

		this.setVoiceFile();

		}

	}
toggleCollapse() {
		this.isCollapsed = !this.isCollapsed;
	}
setVoiceFile(){
	
	this.voicerecordservice.getVoiceRecordList(this.UserProfileId,0).subscribe(
		(data:any) =>{
		  this.voicelist = data
		  for (let voiceData of this.voicelist){
			if(voiceData.fileUrl == this.musiconhold) {
			  this.musiconholdInitial = voiceData.name
			}
			if(voiceData.fileUrl == this.failoverDestination) {
			  this.voiceInitial = voiceData.name
			}
		  }
		}
	)
}



public customPatternValid(config: any) {
    return (control: FormControl) => {
      let urlRegEx: RegExp = config.pattern;
      if (control.value && !String(control.value).match(urlRegEx)) {
        return {
          invalidMsg: config.msg
        };
      }
    };
}

	
	addagents = function(type){

		if(this.agentNumbers == undefined){
			this.agentNumbers = ""
		}

		if(this.agentNumbers == ""){
			this.agentNumbers += this.agents
			this.agents = ""
		}else{
			this.agentNumbers += ","+this.agents
			this.agents = ""
		}
		
		console.log(this.agentNumbers+"agentNumbers")
        this.agentNumbersList=this.agentNumbers.split(',');
		
	}

	onMusiconHold(data){
		this.musiconhold = data;
	}

	onAgentPath(data){
		this.agentAnnouncement = data;
	}
	
	onVoicePath(data){
	  this.failoverDestination = data;
	}
  
	onAddQueue = function(){
		this.submitted = true;
		this.user = this.loginservice.getUserdata(true);
		// alert("user"+(this.user['id'])+this.user['name'])
		this.QueueForm.value.failOverDestinationType = this.failOverDestinationType;	
		if(this.QueueForm.valid)
		{   this.QueueForm.value.agentNumbers=this.agentNumbers
			this.agentNumbersList=this.agentNumbers.split(',');
			this.QueueForm.value.failoverDestination=this.failoverDestination;
			this.QueueForm.value.musiconhold = this.musiconhold	
			this.QueueForm.value.agentAnnouncement = this.agentAnnouncement
			this.QueueForm.value.stickyIsStrict = this.parseStickyAgentStrategy(this.QueueForm.value.stickyIsStrict)
			this.queueservice.ExistQueue(this.QueueForm.value.name,this.user['id'],this.user['name']).subscribe( 
				(res:any) => {
				  this.existedQueue = res;
				  if(this.existedQueue['status'] == "0"){
					this.queueservice.AddQueue(this.QueueForm.value).subscribe((data:any)=>{
						Swal.fire('Created', 'Department Successfully Created', 'success')
						this.router.navigate(['queue/queuelist/']);
					  })
					  
				}else{
					Swal.fire('Oops..!', 'Please change the Department Name, Already Exist', 'warning')
				}
				})
		} else if(this.QueueForm.invalid){
			Swal.fire('Oops..!', 'Please fill all the required fields', 'warning')	
		}
	}


	onUpdateQueueBtn(id:any) {
		this.submitted = true;
		if(this.failOverDestinationType == '4'){
			console.log("failover voicemail id ", this.failoverDestination)
		}
		if(this.QueueForm.valid)
		{this.QueueForm.value.agentNumbers=this.agentNumbers
			this.agentNumbersList=this.agentNumbers.split(',');
			console.log("FormValues======",JSON.stringify(this.QueueForm.value))
		this.QueueForm.value.name = this.queueName
		if(this.failoverDestination == undefined){
			this.QueueForm.value.failoverDestination = this.failoverDestinationInitail
		}
		else{
			this.QueueForm.value.failoverDestination = this.failoverDestination
		}
		this.QueueForm.value.musiconhold = this.musiconhold
		this.QueueForm.value.agentAnnouncement = this.agentAnnouncement
		this.QueueForm.value.agentNumbers=this.agentNumbers
		this.QueueForm.value.stickyIsStrict = this.parseStickyAgentStrategy(this.QueueForm.value.stickyIsStrict)
		this.queueservice.EditQueue(id, this.QueueForm.value).subscribe(
		(res:any) => {
			this.router.navigate(['queue/queuelist/']);
			Swal.fire('Updated', 'Department Successfully Updated', 'success')
			}
	  )
		}

	}

	onEdit(id) {
		this.id = id
		this.voicerecordservice.getVoiceRecord(this.id).subscribe(
		  (res:any) => {
			if(res && res.id) {
			  this.id = res.id
			}
		  }
		)
	  }
	


	onDestinyType(val:any) {
		this.failOverDestinationType = val.selectedIndex
		this.failoverDestinationInitail = ''
		if(this.failOverDestinationType == '1'){
			this.destinyLabel = 'Select Department'
		}
		if(this.failOverDestinationType == '4'){
			this.destinyLabel = 'Select Voicemail template'
		}
	}

	selectQueue(item) {
		this.failoverDestination = item.name
	}

	
	onChangeSearchQueue(val: string) {
		if(this.failOverDestinationType == "1"){
			this.userData = this.loginservice.getUserdata(true);
			this.queueservice.searchQueue(val ,this.userData.id).subscribe(
				(data:any) =>{
					this.destinationList = data
				}
			)
		}
		else if(this.failOverDestinationType == "4"){
			this.userData = this.loginservice.getUserdata(true)
			this.voicemailservice.getVoicemailTemplate(this.userData.id).subscribe(
				(data:any) =>{
					this.destinationList = data
				}
			)
		}
	}

	onFocusedQueue(e){
		if(this.failOverDestinationType == "1"){
			this.userData = this.loginservice.getUserdata(true);
			this.queueservice.getQueues(this.userData.id).subscribe(
				(data:any) =>{
					this.destinationList = []
					this.destinationList = data
				}
			)
		}
		else if(this.failOverDestinationType == "4"){
			this.userData = this.loginservice.getUserdata(true);
			this.voicemailservice.getVoicemailTemplate(this.userData.id).subscribe(
				(data:any) =>{
					this.destinationList = []
					this.destinationList = data
				}
			)
		}
	}

	  
	get f() { return this.QueueForm.controls; }

	
	
	  selectagentUsers(item) {
		this.agents=item.name;
		this.agentUser = item.id
		var temp = {'id':item.id, 'name': item.name}
		this.users.push(temp)
		this.agentUsersIds.push(item.id)
		this.agentUsersIds = this.agentUsersIds.filter(function(elem, index, self) {
		  return index === self.indexOf(elem);
		})
	  }
	
	  deleteagentUsers(item){
		const index = this.users.indexOf(item, 0);
		if (index > -1) {
		  this.users.splice(index, 1);
		  this.agentUsersIds.splice(index, 1);
		}
		
	  }
		
	  onChangeSearchagentUsers(val: string) {
		// this.dialerService.searchSubUser(val ,this.UserProfileId).subscribe()

	  }
	

	  onFocusedagentUsers(e){
		this.userData = this.loginservice.getUserdata(true);		 
		  this.usermanagementService.getAgentUsers(this.userData.id).subscribe(
			(res:any) => {
        this.agentUsersList = res["data"];
      }
    )
	  }

	  onFocusedVirtualSipUsers(e){
		this.userData = this.loginservice.getUserdata(true);		 
		  this.usermanagementService.getVirtualSipUsers(this.userData.id).subscribe(
			(res:any) => {
        this.VirtualSipUsersList = res["data"];
      }
    )
	  }
	
	  selectVirtualSipUsers(item) {
		this.agents=item.name;
		this.agentUser = item.id
		var temp = {'id':item.id, 'name': item.name}
		this.users.push(temp)
		this.agentUsersIds.push(item.id)
		this.agentUsersIds = this.agentUsersIds.filter(function(elem, index, self) {
		  return index === self.indexOf(elem);
		})
	  }

	  deleteItem(index: number) {
        this.agentNumbersList.splice(index, 1);
		this.agentNumbers=this.agentNumbersList.join(',');
    } 
	
	moveUp(index: number) {
        if (index > 0) {
            const temp = this.agentNumbersList[index - 1];
            this.agentNumbersList[index - 1] = this.agentNumbersList[index];
            this.agentNumbersList[index] = temp;
			this.agentNumbers=this.agentNumbersList.join(',');
        }
    }

    moveDown(index: number) {
        if (index < this.agentNumbersList.length - 1) {
            const temp = this.agentNumbersList[index + 1];
            this.agentNumbersList[index + 1] = this.agentNumbersList[index];
			this.agentNumbersList[index] = temp;
			this.agentNumbers=this.agentNumbersList.join(',');
        }
	}

	parseStickyAgentStrategy(value: string) {
		return value === "true" ? true : false;
	}
}
