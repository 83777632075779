import { Component, OnInit ,Input, EventEmitter,Output} from '@angular/core';
import { NotificationService } from "src/app/notification/notification.service";
import Swal from "sweetalert2";
import { LoginService } from "src/app/login.service";
import { ReportService } from "../../inbound-report/report.service";
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { CustomFieldService } from  "../../addressbook/custom-field/custom-field.service"
import { and } from '@angular/router/src/utils/collection';


@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  @Input() public caller_id:any;
  @Input() public customer:any;
  @Output() contactUpdated = new EventEmitter<number>();
  @Input() deleteCustomerIcon: boolean = false;


  addresspopup: Boolean = false;
  mobile_num: string = "";
  cont_name: string = "";
  cont_mail: string = "";
  cont_id: string = "";
  cont_error: string = "";
  permissions: any;
  cont_permission: string = "";
  permissions_added_by: any;
  userData: any;
  userType: any;
  customFieldData: [] = [];
  customFieldValuesForm: FormGroup;
  permissions_vissible: Boolean = true;

  constructor(
    private loginservice: LoginService,
    private reportservice: ReportService,
    private notifyService: NotificationService,
    private customfieldservice: CustomFieldService,
    private formBuilder: FormBuilder,


  ) { 

  }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.permissions = this.userData.permissions;
    this.userType = localStorage.getItem("userType");
    this.customFieldValuesForm = this.formBuilder.group({})   
  }



  DeleteContact(number){
    var body={"delete_type":"customer", "customer_id":this.cont_id}
    this.reportservice.deletecustomer(body,this.userData.id).subscribe((res:any)=>{

      if (this.deleteCustomerIcon) {
        let con_obj = document.getElementsByClassName(number);
        for (let i = 0; i < con_obj.length; i++) {
          con_obj[i].innerHTML = '';
        }

        this.contactUpdated.emit(-1);
      }
      else {
        var html= '<div ><span  class="icon is-small" style="cursor: pointer;"><i  class="fa fa-address-book cursor-pointer div_'+number+'" style="color: green;"></i></span></div><div  class="is-flex-direction-column ml-1 ">'+
        '<div  class="ng-star-inserted">'+ number+'</div><div > </div></div></div>'

        let con_obj = document.getElementsByClassName(number);
        for (var i = 0; i < con_obj.length; i++) {
          con_obj[i].innerHTML = html;
        }

        let objs = document.getElementsByClassName("div_" + number);
        for (var i = 0; i < objs.length; i++) {
          objs[i].addEventListener("click", () => {
            this.openContact(number,'');
          });
        }
      }

      this.addresspopup = !this.addresspopup


      this.notifyService.sendNotification("",res,"is-success");
    })
  }


  CreateContact() {
    let data;

    if (this.cont_name == "") {
      Swal.fire("Please Fill Name");
      return;
    }

    if (!/^\d+$/.test(this.mobile_num)) {
      Swal.fire("The number is not valid");
      return;
    }
    
    if (this.cont_id) {
      data = {
        id: this.cont_id,
        name: this.cont_name,
        number: this.mobile_num,
        email: this.cont_mail,
        userType:this.userType,
        user: parseInt(this.userData.id),
        permission: this.cont_permission,
      };

      if (this.customFieldData) {
        data.custom_field_values = this.customFieldValuesForm.value
      }
      this.reportservice
        .updatecontact(data, this.cont_id)
        .subscribe((res: any) => {
          if (res.status == 1) {
            Swal.fire({
                title: 'Warning!',
                text: 'You can’t edit this number because this contact is created by admin.',
                confirmButtonText: 'OK',
                allowOutsideClick: false, // Prevent closing by clicking outside the dialog
                allowEscapeKey: false,    // Prevent closing with the Escape key
              });
          }else{
            if (res.id) {
              if (res.message == "") {
                ""
              }

              let con_obj = document.getElementsByClassName(this.cont_id);
              for (var i = 0; i < con_obj.length; i++) {
                con_obj[i].innerHTML = this.cont_name;
              }

              this.notifyService.sendNotification("Success", "Contact Updated Succesfully", "is-success");

              this.contactUpdated.emit(res.id);

            } else if (res.error) {
              this.cont_error = res.error;
              return;
            } else {
              this.notifyService.sendNotification(
                "",
                "Error in Creating conatct",
                "is-danger"
              );
            }
        }
          this.addresspopup = false;
        });
    } else {
      data = {
        name: this.cont_name,
        number: this.mobile_num.slice(-10),
        email: this.cont_mail,
        user: parseInt(this.userData.id),
        userType: this.userType,
        permission: this.cont_permission,
      };


      if (this.customFieldData) {
        data.custom_field_values = this.customFieldValuesForm.value
      }

      this.reportservice.createContact(data).subscribe((res: any) => {
        console.log('response of CreateContact   :  ',res)
        if (res.id) {
          var htmlCode =
            '<div class="div_' +
            res.number +
            '" >' +
            '<span class="icon is-small" style="cursor: pointer;">' +
            '<i class="fa fa-address-book sideicon" style="font-size: 21px;color: rgb(74 74 74 / 90%);"></i>'+
            "</span>" +
            "</div>" +

            '<div class="is-flex-direction-column ml-1 ">'+
        '<strong class="'+res.id+' text-sm">'+ res.name+'</strong></br>'+
        '<strong class="has-text-grey text-sm	">'+res.number+'</strong>'+
        '</div>' 

          let con_obj = document.getElementsByClassName(this.mobile_num);
          for (var i = 0; i < con_obj.length; i++) {
            con_obj[i].innerHTML = htmlCode;
          }

          let objs = document.getElementsByClassName("div_" + res.number);
          for (var i = 0; i < objs.length; i++) {
            objs[i].addEventListener("click", () => {
              this.openContact(res.number, res.id);
            });
          }
          this.notifyService.sendNotification("Success","Contact Created Succesfully","is-success");
        } else if (res.error) {
          this.cont_error = res.error;
          return;
        } else {
          this.notifyService.sendNotification(
            "",
            "Error in Creating conatct",
            "is-danger"
          );
          return;
        }

        this.addresspopup = false;
        this.cont_name = "";
        this.cont_id = "";
        this.cont_mail = "";
        this.cont_permission = "";
      });
    }
  }


  fetchCustomFields(user_id:any){
    this.customfieldservice.getCustomField(user_id).subscribe(
      (response:any) => {
        this.customFieldData = response.custom_field_names
        if (this.customFieldData && this.customFieldData.length > 0) {
          this.customFieldData.forEach(custom_field_names => {
            this.customFieldValuesForm.addControl(custom_field_names, new FormControl(''));
          })
        }
      }
    )
  }

  openContact(mobile, id) {
    if (this.userType == 'dialerUser' || this.userType == 'agentUser' || this.userType == 'virtualUser'){
      this.fetchCustomFields(this.userData.admin)
    }
    else{
      this.fetchCustomFields(this.userData.id)
    }

    this.mobile_num = mobile;
    this.addresspopup = true;
    this.cont_name = "";
    this.cont_id = "";
    this.cont_mail = "";
    this.cont_permission = "";
    console.log("id ----------------------->     :     ",id)
    if (id) {
      console.log("ididididid  : ,2",id)
      this.reportservice
        .getcontact(id, this.userData.id, this.userType)
        .subscribe((res: any) => {
          console.log("user id is     :   ", res.message)
            if (res.id) {
              this.cont_name = res.name;
              this.cont_mail = res.email;
              this.cont_id = res.id;
              this.cont_permission = res.permission || "";
              this.permissions_added_by = res.permission_added_by;
              this.customFieldValuesForm.patchValue(res.custom_field_values);
            } else {
              this.notifyService.sendNotification(
                "",
                "Error in Getting conatct",
                "is-danger"
              );
            }
        });
    }
  }

  resetForm() {
    this.customFieldValuesForm.reset();
  }
}
