import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WhatsappService } from '../whatsapp-services';
import { LoginService } from 'src/app/login.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-whatsapp-settings',
  templateUrl: './whatsapp-settings.component.html',
  styleUrls: ['./whatsapp-settings.component.css']
})
export class WhatsappSettingsComponent implements OnInit {

  
  @Output() userEmitter: any = new EventEmitter();
  userInitial: string | null = null;
  userClearTrigger = false;
  userReadOnly: boolean = false;
  userRequired: boolean = false;
  userData: any;
  dataTable: any;
  ivrTable: any;
  userProfileId: string = "";
  isValidUser: boolean = false;
  isValidName: boolean = true;
  whatsappUserId: string | null = null;
  settingsForm: FormGroup;
  ivrForm: FormGroup;
  selectedTab: string = "settings";
  settingsId: string | null = null;
  ivrSettingsId: string | null = null;
  settingsList: any[] = [];
  ivrSettingsList: any[] = [];
  ivrList:any[] = []
  DIDList: any[] = [];
  templateList: any[] = [];
  agentList: any[] = [];
  callStatusList = [
    { id: "ANSWERED", value: "ANSWERED" },
    { id: "NOANSWER", value: "NOANSWER" },
    { id: "BUSY", value: "BUSY" },
    { id: "UNAVAILABLE", value: "UNAVAILABLE" },
    { id: "NOINPUT", value: "NOINPUT"},
    { id: "CANCEL", value: "CANCEL"}
  ]

  callDirectionList = [
    { id: "1", value: "Inbound" },
    { id: "2", value: "Outbound" },
    { id: "3", value: "Both" }
  ]

  receipentList = [
    { id: "1", value: "Agent" },
    { id: "2", value: "Customer" },
    { id: "3", value: "Both"}
  ]

  outboundOptionsList = [
    { id: "1", value: "Autocall"},
    { id: "2", value: "Virtual Sip"},
    { id: "3", value: "Both" }
  ]

  dropdownSettings = {
    singleSelection: false,
    text: "Select vsip agents",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    enableSearchFilter: true,
    classes: "custom-multi-select",
  };

  constructor(
    private whatsappService: WhatsappService,
    private loginService: LoginService,
    private fb: FormBuilder
  ) { 
    this.settingsForm = this.fb.group({
      name: ["", Validators.required],
      did: ["", Validators.required],
      status: ["", Validators.required],
      template: ["", Validators.required],
      direction: ["", Validators.required],
      target: ["", Validators.required],
      outboundType: [""],
      agents: [[]],
      active: [true]
    })

    this.ivrForm = this.fb.group({
      name: ["", Validators.required],
      settings: ["", Validators.required],
      ivr: ["", Validators.required],
      dtmf: ["", Validators.required],
      active: [true]
    })
  }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(true);    
    this.intitializerUserSettings();
    this.initializeDatatable();
    this.initializeIVRTable();
  }

  intitializerUserSettings() {

    if (this.userData.usergroup != "superadmin") {
      this.userReadOnly = true;
    } else {
      this.updateDataTable();
    }
  }

  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }

  initializeDIDList(userId) {
    this.whatsappService.getDIDList(userId).subscribe((res: any) => {
      if (res) {
        this.DIDList = res;
      }
    })
  }

  initializeIVRList(userProfileId) {
    this.whatsappService.getIVRList(userProfileId).subscribe((res: any) => {
      if (res) {
        this.ivrList = res;       
      }
    })
  }

  initializeTemplateList(userId) {

    let qParams = []
    if (this.userData.usergroup === "superadmin") {
      qParams.push({
        key: "scope", value: "superadmin"
      })
    } else {
      qParams.push(
        { key: "scope", value: "customer" },
        { key: "userprofile_id", value: userId }
      )
    }

    this.whatsappService.getTemplatesList(qParams).subscribe((res: any) => {
      if (res.status === "1") {
        this.templateList = res.data.filter(item=> item.is_valid === true)
      }
    })
  }

  initializeVsipAgentList(userId) {
    const data = {
      scope: "bon_web",
      id: userId
    }

    this.whatsappService.getVsipAgentList(data).subscribe((res: any) => {
      if (res) {
        this.agentList = res.data.map(item => ({
          id: item.id,
          itemName: item.username
        }))
      }
    })
  }

  initializeDatatable() {
    this.dataTable = $('#settingsTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { data: 'slno', title: 'Sl No' },
        { data: 'name', title: 'Name' },
        { data: 'whatsapp_user_name', title: 'User' },
        { data: 'did_number', title: 'DID' },
        { data: 'call_status', title: 'Status' },
        { data: 'template_name', title: 'Template' },
        { data: 'target_name', title: 'Recepient'},
        { data: 'direction_name', title: 'Direction' },
        { data: 'outbound_type_name', title: 'Outbound Type' },
        { data: 'status', title: 'Status'},
        { data: 'action', title: "Action"}
      ]
    });
  }

  updateDataTable() {
    const qParams = [
      { key:"scope", value: this.userData.usergroup === "superadmin" ? "superadmin" : "customer" },
      { key: "whatsapp_user", value: this.userData.usergroup === "superadmin" ? null :this.whatsappUserId}
    ]

    this.whatsappService.getWhatsappSettingsList(qParams).subscribe((res: any) => {
      if (res.status === "1") {
        this.settingsList = res.data.map((item, index) => ({
          ...item,
          slno: index + 1,
          action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                    <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`,
          status: `<input type="checkbox" ${item.active ? "checked" : ""} disabled/>`
        }))
        this.dataTable.clear();
        this.dataTable.rows.add(this.settingsList);
        this.dataTable.draw();    

        $('#settingsTable tbody').on('click', '#btn-delete', (event: any) => {

          const row = this.dataTable.row($(event.target).closest('tr')).data();
          if (row) {
            this.deleteSettings(row.id);
          }
        });

        $('#settingsTable tbody').on('click', '#btn-edit', (event: any) => {

          const row = this.dataTable.row($(event.target).closest('tr')).data();
          if (row) {
            this.editSettings(row);
          }
        });
      }
    })    
  }

  deleteSettings(id) {
    Swal.fire({
      title: "Are you sure ?",
      text: "The settings will be deleted",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete.",
      cancelButtonText: "No, Dont delete.",
    }).then((res) => {
      if (!res.dismiss) {
        this.whatsappService.deleteWhatsappSettings(id).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Settings deleted successfully.", "success");
            this.updateDataTable();
          } else {
            Swal.fire("Something went wrong", "Failed to delete settings", "error");
          }
        })
      }
    })
  }

  editSettings(row) {
   
    this.settingsId = row.id;    
    this.settingsForm.patchValue({
      name: row.name,
      did: row.did,
      status: row.call_status,
      template: row.template,
      target: row.target,
      direction: row.direction.toString(),
      outboundType: row.outbound_type ? row.outbound_type : "",
      agents: this.agentList.filter(agent => row.vsip_agents.some(id => agent.id == id)),
      active: row.active
    })
  }

  initializeIVRTable() {
    this.ivrTable = $('#ivrTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { data: 'slno', title: 'Sl No' },
        { data: 'name', title: 'Name' },
        { data: 'settings_name', title: 'Settings' },
        { data: 'ivr_name', title: 'IVR' },
        { data: 'dtmf', title: 'DFMF Sequence' },
        { data: 'status', title: 'Status' },
        { data: 'action', title: "Action" }
      ]
    });
  }

  updateIVRTable() {
    this.whatsappService.getIVRSettingsList(this.whatsappUserId).subscribe((res: any) => {
      if (res.status === "1") {
        this.ivrSettingsList = res.data.map((item, index) => ({
          ...item,
          slno: index + 1,
          action: `<a id="btn-ivrEdit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                    <a id="btn-ivrDelete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`,
          status: `<input type="checkbox" ${item.active ? "checked" : ""} disabled/>`
        }))
        this.ivrTable.clear();
        this.ivrTable.rows.add(this.ivrSettingsList);
        this.ivrTable.draw();   

        $('#ivrTable tbody').on('click', '#btn-ivrDelete', (event: any) => {

          const row = this.ivrTable.row($(event.target).closest('tr')).data();
          if (row) {
            this.deleteIVRSettings(row.id);
          }
        });

        $('#ivrTable tbody').on('click', '#btn-ivrEdit', (event: any) => {

          const row = this.ivrTable.row($(event.target).closest('tr')).data();
          if (row) {
            this.editIVRSettings(row);
          }
        });
    
      }
    })
  }

  deleteIVRSettings(id) {
    Swal.fire({
      title: "Are you sure ?",
      text: "IVR Settings will be deleted.",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete.",
      cancelButtonText: "No, Dont delete.",
    }).then(res => {
      if (!res.dismiss) {
        this.whatsappService.deleteIVRSettings(id).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "IVR Settings deleted successfully.", "success");
            this.updateIVRTable();
          } else {
            Swal.fire("Something went wrong!", "Failed to delete IVR Settings", "error");
          }
        })
      }
    })
  }

  editIVRSettings(row) {
    this.ivrSettingsId = row.id 
    this.ivrForm.patchValue({
      name: row.name,
      settings: row.settings,
      ivr: row.ivr,
      dtmf: row.dtmf,
      active: row.active
    })
  }

  userEvent(userIds) {   
    this.userProfileId = userIds.userProfileId;
    this.whatsappUserId = userIds.whatsappUserId;
    this.initializeDIDList(this.userProfileId);
    this.initializeTemplateList(this.userProfileId);
    this.initializeVsipAgentList(this.userProfileId);
    this.initializeIVRList(this.userProfileId);

    if (this.whatsappUserId != null) {
      this.isValidUser = true;  
      this.updateDataTable();
      this.updateIVRTable();
    } else {
      this.isValidUser = false;
    }
    
  }

  onNameBlur(event) {
    const name = event.target.value;
    const settings = this.settingsList.find(item => item.name == name)
    const nameControl = this.settingsForm.get('name')
    
    if (this.settingsId && this.settingsId != settings.id) {
      this.isValidName = false;
      nameControl.setErrors({ nameExists: true });
    }else if (!this.settingsId && settings != undefined) {
      this.isValidName = false;
      nameControl.setErrors({ nameExists: true });
    }else{
      this.isValidName = true;
      nameControl.setErrors(null);
    }
    
  }

  onDirectionChange(event) {
    if (event.target.value != "1") {
      this.settingsForm.get('outboundType').setValidators([Validators.required]);
    } else {
      this.settingsForm.get('outboundType').clearValidators();
      this.settingsForm.get('agents').clearValidators();
      this.settingsForm.patchValue({
        outboundType: "",
        agents: []
      })
    }
    
  }

  onOutboundTypeChange(event) {
    if (event.target.value == "2") {
      this.settingsForm.get('agents').setValidators([Validators.required]);
    } else {
      this.settingsForm.get('agents').clearValidators();
      this.settingsForm.patchValue({
        agents: []
      })
    }
  }

  onAgentSelect(event) {
    
  }

  OnAgentDeSelect(event) {
    
  }

  onAgentSelectAll() {
    
  }

  onAgentDeSelectAll() {
    
  }

  onSubmit() {
    if (this.whatsappUserId) {
      this.markFormGroupAsTouched(this.settingsForm)
      if (this.settingsForm.valid) {
        const data = {
          id: this.settingsId,
          name: this.settingsForm.value.name,
          whatsapp_user: this.whatsappUserId,
          did: this.settingsForm.value.did,
          call_status: this.settingsForm.value.status,
          template: this.settingsForm.value.template,
          target: this.settingsForm.value.target,
          direction: this.settingsForm.value.direction,
          outbound_type: this.settingsForm.value.outboundType != "" ? this.settingsForm.value.outboundType : null,
          vsip_agents: this.settingsForm.value.agents.map(item => item.id),
          active: this.settingsForm.value.active

        }
        if (this.settingsId) {
          this.whatsappService.updateWhatsappSettings(data).subscribe((res: any) => {
            if (res.status === "1") {
              Swal.fire("Success", "Settings updated successfully.", "success");
              this.resetSettingsForm();
              this.updateDataTable();
            } else {
              Swal.fire("Sonething went wrong!", "Failed to update settings", "error");
            }
          })
          
        } else {
          this.whatsappService.addWhatsappSettings(data).subscribe((res: any) => {
            if (res.status === "1") {
              Swal.fire("Success", "Settings created successfully.", "success");
              this.resetSettingsForm();
              this.updateDataTable();
            } else {
              Swal.fire("Something went wrong!", "Failed to create settings", "error");
            }
          })
        }
      } else {
        this.logFormErrors(this.settingsForm);        
      }      
      
    } else {
      this.isValidUser = false;
    }
    
  }

  onUpdateCancel() {
    this.resetSettingsForm();
  }

  resetSettingsForm() {
    this.settingsId = null;
    this.settingsForm.reset();
    this.settingsForm.patchValue({
      name: "",
      did: "",
      status: "",
      template: "",
      target: "",
      direction: "",
      outboundType: "",
      agents: [],
      active: true
    })
  }

  resetIVRForm() {
    this.ivrSettingsId = null;
    this.ivrForm.reset();
    this.ivrForm.patchValue({
      settings: "",
      ivr: "",
      active: true
    })
  }


  onIVRTemplateSubmit() {
    this.markFormGroupAsTouched(this.ivrForm)
    if (this.ivrForm.valid) {
      const data = {
        id: this.ivrSettingsId,
        name: this.ivrForm.value.name,
        settings: this.ivrForm.value.settings,
        ivr: this.ivrForm.value.ivr,
        dtmf: this.ivrForm.value.dtmf,
        active: this.ivrForm.value.active
      }

      if (this.ivrSettingsId) {
        this.whatsappService.updateIVRSettings(data).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "IVR Settings updated successfully.", "success");
            this.updateIVRTable();
            this.resetIVRForm();
          } else {
            Swal.fire("Something went wrong!", "Failed to create IVR Settings", "error");
          }
        })
      } else {
        this.whatsappService.addIVRSettings(data).subscribe((res:any) => {
          if (res.status === "1") {
            Swal.fire("Success", "IVR Settings added successfully.", "success");
            this.updateIVRTable();
            this.resetIVRForm();
          } else {
            Swal.fire("Something went wrong!", "Failed to create IVR Settings", "error");
          }
        })
      }
    }
  }

  onIVRUpdateCancel() {
    this.resetSettingsForm();
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  logFormErrors(formGroup) {
    Object.keys(this.settingsForm.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control.invalid) {
        console.log(`Errors for ${key}:`, control.errors);
      }
    });
  }




}
