import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { LoginService } from 'src/app/login.service';
import { WhatsappService } from '../whatsapp-services';
import { ExcelService } from 'src/app/inbound-report/excel.service';
import * as jsPDF from "jspdf";
import * as saveAs from "file-saver";
import { DatePipe } from "@angular/common";
import * as moment from 'moment';


declare var $: any;

@Component({
  selector: 'app-whatsapp-report',
  templateUrl: './whatsapp-report.component.html',
  styleUrls: ['./whatsapp-report.component.css'],
  providers: [DatePipe],
})
export class WhatsappReportComponent implements OnInit, AfterViewInit {

  reportForm: FormGroup;
  userData: any;
  templateList: any[] = [];
  dataTable: any;
  messageList: any[] = []
  messageStatusList = [
    { id: null, value: "Select a template" },
    { id: true, value: "Success" },
    { id: false, value: "Failed"}
  ]
  defaultDateFrom: Date;
  defaultDateTo: Date;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private whatsappService: WhatsappService,
    private datePipe: DatePipe,
    private excelService: ExcelService
  ) { 
    this.reportForm = this.fb.group({
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      template: [""],
      phoneNumber: ["", Validators.pattern('^[0-9]{10}$')],
      status: [null]
    })
  }

  ngOnInit() {
    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);

    this.userData = this.loginService.getUserdata(true);
    this.reportForm.get('fromDate').valueChanges.subscribe((value) => {
      this.onFromDateChange(value);
    });

    this.reportForm.get('toDate').valueChanges.subscribe((value) => {
      this.onToDateChange(value);
    });

  }

  ngAfterViewInit(): void {
    this.getTemplateList();
    this.initializerDataTable();
  }

  onSubmit() {
    this.markFormGroupAsTouched(this.reportForm);
  
    if (this.reportForm.valid) {
      const data = {
        from_date: this.datePipe.transform(this.reportForm.value.fromDate, 'yyyy-MM-dd HH:mm:ss'),
        to_date: this.datePipe.transform(this.reportForm.value.toDate, 'yyyy-MM-dd HH:mm:ss'),
        template_id: this.reportForm.value.template,
        phone_number: this.reportForm.value.phoneNumber,
        status: this.reportForm.value.status ? true : false
      };
      this.dataTable.draw();
    }
  }

  onFromDateChange(value: any): void {
    if (value && value !== "") {
      const formattedDate = this.datePipe.transform(value, "yyyy-MM-dd HH:mm:ss");
      this.defaultDateFrom = new Date(formattedDate);
      console.log('From Date Changed:', formattedDate);
    } else {
      const defaultDate = new Date();
      this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
      console.log('From Date Reset to Start of Day:', this.defaultDateFrom);
    }
  }

  onToDateChange(value: any): void {
    if (value && value !== "") {
      const formattedDate = this.datePipe.transform(value, "yyyy-MM-dd HH:mm:ss");
      this.defaultDateTo = new Date(formattedDate);
      console.log('To Date Changed:', formattedDate);
    } else {
      const defaultDate = new Date();
      this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);
      console.log('To Date Reset to End of Day:', this.defaultDateTo);
    }
  }

  getTemplateList() {

    let qParams = []
    if (this.userData.usergroup === "superadmin") {
      qParams.push({
        key: "scope", value: "superadmin"
      })
    } else {
      qParams.push(
        { key: "scope", value: "customer" },
        { key: "userprofile_id", value: this.userData.id }
      )
    }

    this.whatsappService.getTemplatesList(qParams).subscribe((res: any) => {
      if (res.status === "1") {
        this.templateList = res.data
      }
    })
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  initializerDataTable() {
    console.log('Initializing DataTable...');
    this.dataTable = $('#messageTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      pageLength: 1,
      searching: false,      
      language: {
        emptyTable: 'No data available',
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous'
        },
        info: 'Showing _START_ of _TOTAL_ pages',
        infoEmpty: 'Showing 0 of 0 pages'
      },

      columns: [
        { data: 'serial_number', title: 'Sl No' },
        { data: 'date_time', title: 'Date & Time' },
        { data: 'recipient_number', title: 'Phone Number' },
        { data: 'template_name', title: 'Template' },        
        { data: 'status_name', title: 'Status' },
        { data: 'message_id', title: 'Message ID' }
      ],
      serverSide: true,
      ajax: (dataTablesParameters: any, callback) => {

        if (this.reportForm.valid) {
          const qParams = [
            { key: "from_date", value: this.datePipe.transform(this.reportForm.value.fromDate, 'yyyy-MM-dd HH:mm:ss') },
            { key: "to_date", value: this.datePipe.transform(this.reportForm.value.toDate, 'yyyy-MM-dd HH:mm:ss'), },
            { key: "template_id", value: this.reportForm.value.template },
            { key: "phone_number", value: this.reportForm.value.phoneNumber },
            { key: "status", value: this.reportForm.value.status},
            { key: "page", value: this.dataTable.page.info().page + 1}
          ]

          if (this.userData.usergroup != "superadmin") {
            qParams.push({ key: "scope", value: "customer" })
            qParams.push({ key: "userprofile_id", value: this.userData.id })
          } else {
            qParams.push({ key: "scope", value: "superadmin" })
          }

          this.whatsappService.getMessageList(qParams).subscribe((res: any) => {
            if (res.status === "1") {
              this.messageList = res.data.map(item => ({
                ...item,
                date_time: this.getFormattedDateTime(item.created_on),
                status_name: item.status? "Success" : "Failed"
              }))
              callback({
                draw: dataTablesParameters.draw,
                recordsTotal: res.count,
                recordsDisplay: 2,
                recordsFiltered: res.total_pages,
                data: this.messageList
              });
            } else {
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: []
              })
            }

          });
        } else {
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          })
        }
      },
      paging: true,
      pagingType: 'simple_numbers',
    });

  }


  downloadPDF() {

    const head = [
      [
        "Sl No",
        "Date & Time",
        "Phone Number",
        "Template",
        "Status",
        "Message Id"
      ]
    ]

    let dataArray = []

    for (let element of this.messageList) {
      dataArray.push([
        element["serial_number"],
        element["date_time"],
        element["recipient_number"],
        element["template_name"],
        element.status ? "Success": "Failed",
        element["message_id"]
      ])
    }
    
    let pdf = new jsPDF();
    pdf.text("Whatsapp Report", 11, 8);
    pdf.setFontSize(3);
    pdf.setTextColor(100);
    (pdf as any).autoTable({
      head: head,
      body: dataArray,
      theme: "grid",
      styles: { fontSize: 4 },
      tableWidth: "auto",
      columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
      margin: { top: 10 },
    });

    pdf.save("Whatsapp Report.pdf")
  }

  downloadExcel() {
    let dataArray = []
    for (let element of this.messageList) {
      dataArray.push({
        "Sl No": element["serial_number"],
        "Date & Time": element["date_time"],
        "Phone Number": element["recipient_number"],
        "Template": element["template_name"],
        "Status": element.status ? "Success" : "Failed",
        "Message Id": element["message_id"]
      })
    }

    this.excelService.exportAsExcelFile(dataArray, "Whatsapp Report")
  }

  getFormattedDateTime(datetime): string {
    const formattedDateTime = new Date(datetime).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const [date, time] = formattedDateTime.split(", ");
    const formattedDate = date.replace(/\//g, "-");
    return `${formattedDate}, ${time}`;
  }
}
